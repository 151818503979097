export class StaticticRound {

    constructor(
        private wrongAnswersCount: number = 0,
        private startTime: Date = new Date(),
        private finishTime: Date = new Date(),
        private passTime: number = 0,
        private isDone: boolean = false
    ) { }

    private countPassTimeInSeconds() {
        this.passTime = (this.finishTime.getTime() - this.startTime.getTime()) / 1000;
    }

    public finishRound(isDone: boolean) {
        this.finishTime = new Date();
        this.countPassTimeInSeconds();
        this.isDone = isDone;
    }

    public addWrongAnswer() {
        this.wrongAnswersCount++;
    }

    public getPassTime() {
        return this.passTime;
    }

    public getRoundStatistic() {
        var rounds = [{
            setup: "",
            startTime: "",
            finishTime: "",
        }];

        return {
            wrongAnswersCount: this.wrongAnswersCount,
            startTime: this.startTime,
            finishTime: this.finishTime,
            passTime: this.passTime,
            wasDone: this.isDone
        }
    }
}
import React from 'react';
import { connect } from 'react-redux';
import './App.css';
import GameSettings from './components/GameSettings/GameSettings';
import Game from './components/Game/Game';
import params from './service/urlParams';
import { setUrlParams } from './store/actions/general';

interface IAppProps {
    options: any;
    setUrlParams: any;
}

class App extends React.PureComponent<IAppProps> {
    render() {
        const screen = this.props.options === null ? <GameSettings /> : <Game />;

        let urlParams = params.getUrlParams(window.location.href);
        this.props.setUrlParams(urlParams);

        return <>{screen}</>;
    }
}

const mapStateToProps = (state: any) => ({
    options: state.general.options,
    setUrlParams,
});

export default connect(mapStateToProps, { setUrlParams })(App);

import { StatisticLevel } from '../helpers/statisticLevel';
import { SeriesOption } from '../types/SeriesOption';

export class StatisticService {
    public startSessionTime: Date;
    public currentLevel: StatisticLevel;
    private levelStorage: StatisticLevel[];

    constructor(levelOptions: SeriesOption | null) {
        this.currentLevel = new StatisticLevel(levelOptions);
        this.levelStorage = new Array<StatisticLevel>();
        this.startSessionTime = new Date();
    }

    public wrongAnswer() {
        this.currentLevel.wrongAnswer();
        console.log('wrong answer', this);
    }

    public passRound(isPreviousRoundDone: boolean) {
        this.currentLevel.nextRound(isPreviousRoundDone);
    }

    public passLevel(isPreviousRoundDone: boolean) {
        this.currentLevel.doneLevel(isPreviousRoundDone);
        this.levelStorage.push(this.currentLevel);
        console.log('pass level', this);
    }

    public newLevel(nextOptions: SeriesOption | null) {
        this.currentLevel = new StatisticLevel(nextOptions);
    }

    public abort() {
        this.currentLevel.abort();
        this.levelStorage.push(this.currentLevel);
    }

    public getSessionStatistic() {
        const levelsStat = new Array<any>();
        this.levelStorage.forEach(l => levelsStat.push(l.getLevelStatistic()));
        return {
            // startSessionTime: this.startSessionTime,
            levels: levelsStat,
        };
    }
}

const lrs = 'https://my.2035.university';

const environment = {
	LRSResultFormat: `${lrs}/xapi/v1/results/selfportrait`,
	proxyHost: 'https://bypass.2035.university/api/activity',
	t: 'c8d46d341bea4fd5bff866a65ff8aea9',
	production: true,
};

interface IframeParams {
	token: string;
	unti_id: string;
	activity_uuid: string;
}

class IntegrationService {
	public categories = [
		{name: 'Знания и мышление'},
		{name: 'Личные качества'},
		{name: 'Профессиональные качества'},
	];

	public sendStart(params: IframeParams): void {
		console.log(params);
		fetch(`${environment.proxyHost}/${params.activity_uuid}/start`, {
			body: JSON.stringify({
				unti_id: params.unti_id,
				token: params.token,
				t: environment.t,
			}),
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	public sendEnd(params: IframeParams): void {
		fetch(`${environment.proxyHost}/${params.activity_uuid}/end`, {
			body: JSON.stringify({
				unti_id: params.unti_id,
				token: params.token,
				t: environment.t,
			}),
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	public sendResult(params: IframeParams, result: { levels: any[] }): void {
		const extensions = {
			'https://my.2035.university/xapi/v1/results/adaptive-logic': result,
		};

		fetch(`${environment.proxyHost}/${params.activity_uuid}/lrsdata`, {
			body: JSON.stringify({
				unti_id: params.unti_id,
				token: params.token,
				t: environment.t,
				extensions,
			}),
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}
}

const integrationService = new IntegrationService();

export default integrationService;
import React, { ChangeEvent } from 'react';
import SeriesSettings from '../../types/SeriesSettings';
import gameService from '../../service/gameService';
import AppState from '../../types/AppState';
import { setDifficult } from '../../store/actions/general';
import { connect } from 'react-redux';
import storeService from "../../service/storeService";
import integrationService from "../../service/integrationService";

interface IGameSettingsState {
    series: SeriesSettings[];
}

interface IGameSettingsProps {
    setDifficult: typeof setDifficult;
    currentDifficult: number;
}

class GameSettings extends React.Component<IGameSettingsProps, IGameSettingsState> {
    constructor(props: IGameSettingsProps) {
        super(props);
        this.state = {
            series: [
                {
                    roundSettings: {
                        mode: 'single',
                        sequenceLength: 5,
                        sequencesAtStart: 3,
                        attemptsCount: 3,
                    },
                    roundsCount: '3',
                },
                {
                    roundSettings: {
                        mode: 'and',
                        sequenceLength: 6,
                        sequencesAtStart: 3,
                        attemptsCount: 3,
                    },
                    roundsCount: '2',
                },
            ],
        };
    }

    handleDifficulty = (event: ChangeEvent<HTMLSelectElement>) => {
        this.props.setDifficult(+event.target.value);
    };

    handleStart = (e: React.FormEvent<HTMLButtonElement>) => {
        gameService.newGame(this.state, this.props.currentDifficult);

        let urlParams = storeService.getStore().getState().general.urlParams;
        integrationService.sendStart(urlParams);
    };

    render() {
        /*
        let settings = this.state.series.map((serie, idx) => (
            <div key={'comp' + idx}>
                Сложность {idx + 1}. Количество раундов: {serie.roundsCount}. В каждом раунде будет выдано{' '}
                последовательностей: {serie.roundSettings.sequencesAtStart}. Длина последовательностей:{' '}
                {serie.roundSettings.sequenceLength}. Количество попыток:{' '}
                {serie.roundSettings.attemptsCount}
            </div>
        ));
         */

        return (
            <div>
                <div className="rules">
                    <div className="rules-header">
                        <p className="rules-header__title">Правила:</p>
                    </div>
                    <div className="rules-items">
                        <div className="rules-item">
                            <p>
                                Игра предлагает последовательности фигур, для которых всегда
                                выполняется правило вида:
                            </p>
                            <ul>
                                <li>
                                    <b>если</b> предыдущая фигура обладает свойством «а», <b>то</b>{' '}
                                    следующая фигура обладает свойством «б».
                                </li>
                            </ul>
                        </div>
                        <div className="rules-item">
                            <p>Например:</p>
                            <ul>
                                <li>
                                    <b>если</b> большой, <b>то</b> квадрат;
                                </li>
                                <li>
                                    <b>если</b> маленький, <b>то</b> круг;
                                </li>
                                <li>
                                    <b>если</b> квадрат, <b>то</b> белый.
                                </li>
                            </ul>
                        </div>
                        <div className="rules-item">
                            <p>
                                Обратите внимание, что в правиле не могут встречаться одинаковые
                                свойства. Например, игра не может задать правило:
                            </p>
                            <ul>
                                <li>
                                    <b>если</b> большой, <b>то</b> большой.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="game-footer">
                    <button className="button button_l button_red" onClick={this.handleStart}>
                        Начать игру
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    currentDifficult: state.general.currentDifficulty,
    setDifficult: setDifficult,
});

export default connect(mapStateToProps, { setDifficult })(GameSettings);

const actions = {
  SET_OPTIONS: 'SET_OPTIONS',
  SET_CURRENT_ROUND: 'SET_CURRENT_ROUND',
  CLEAR_STATS: 'CLEAR_STATS',
  SET_CURRENT_ROUNT_IND: 'SET_CURRENT_ROUNT_IND',
  SET_DIFFICULT: 'SET_DIFFICULT',
  SET_URL_PARAMS: 'SET_URL_PARAMS',
};

export default actions;

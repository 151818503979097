import SeriesSettings from '../types/SeriesSettings';
import actions from '../store/actions';
import storeService from './storeService';
import RoundInfo from '../types/RoundInfo';
import Rule from '../types/Rule';
import GameMode from '../types/GameMode';
import Condition from '../types/Condition';
import Element from '../types/Element';

class GameService {
    newGame(options: { series: SeriesSettings[] }, difficultyMode: number): void {
        const rule = this.generateRandomRule(options.series[difficultyMode].roundSettings.mode);
        const sequences: Element[][] = [];
        for (let i = 0; i < options.series[difficultyMode].roundSettings.sequencesAtStart; i++) {
            sequences.push(
                this.generateRandomSequence(
                    rule,
                    options.series[difficultyMode].roundSettings.sequenceLength
                )
            );
        }

        const currentRound: RoundInfo = {
            settings: options.series[difficultyMode].roundSettings,
            attemptsLeft: options.series[difficultyMode].roundSettings.attemptsCount,
            sequenceCount: options.series[difficultyMode].roundSettings.sequencesAtStart,
            rule,
            sequences,
        };
        storeService.getStore().dispatch(actions.general.setCurrentRound(currentRound));

        storeService.getStore().dispatch(actions.general.clearStats());
        storeService.getStore().dispatch(actions.general.setOption(options.series));
    }

    generateRandomRule = (mode: GameMode): Rule => {
        const conditionIf = this.generateRandomCondition();
        const conditionThen = this.generateRandomCondition(conditionIf);
        return {
            type: mode,
            conditionIf,
            conditionThen,
            conditionAndOr:
                mode === 'single' ? null : this.generateRandomCondition(conditionIf, conditionThen),
        };
    };

    generateRandomCondition = (...conditionsAlreadyUsed: Condition[]): Condition => {
        const conditions: Condition[][] = [
            ['big', 'small'],
            ['round', 'square'],
            ['white', 'black'],
        ];
        let conditionType: Condition[];
        let condition: Condition;

        if (conditionsAlreadyUsed.length < 2) {
            do {
                conditionType = conditions[Math.floor(Math.random() * conditions.length)];
                condition = conditionType[Math.floor(Math.random() * conditionType.length)];
            } while (conditionsAlreadyUsed.includes(condition));
        } else {
            do {
                conditionType = conditions[Math.floor(Math.random() * conditions.length)];
                condition = conditionType[Math.floor(Math.random() * conditionType.length)];
            } while (
                conditionsAlreadyUsed.includes(condition) ||
                conditionType.includes(conditionsAlreadyUsed[0])
            );
        }
        return condition;
    };

    generateRandomSequence = (rule: Rule, length: number): Element[] => {
        const ret: Element[] = [];
        ret[0] = this.generateRandomElement();
        for (let i = 1; i < length; i++) {
            let e: Element;

            // generate ranom elements until requirements met
            do {
                e = this.generateRandomElement();
            } while (!this.checkRule(ret[i - 1], e, rule));

            ret.push(e);
        }
        return ret;
    };

    generateRandomElement = (): Element => ({
        size: ['big', 'small'][Math.floor(Math.random() * 2)] as 'big' | 'small',
        color: ['black', 'white'][Math.floor(Math.random() * 2)] as 'black' | 'white',
        shape: ['square', 'round'][Math.floor(Math.random() * 2)] as 'square' | 'round',
    });

    checkElementCondition = (e: Element, c: Condition): boolean => {
        if (c === 'big' || c === 'small') {
            return e.size === c;
        }
        if (c === 'black' || c === 'white') {
            return e.color === c;
        }
        if (c === 'round' || c === 'square') {
            return e.shape === c;
        }
        return false; // actually this line won't run
    };

    checkSequence = (rule: Rule, s: Element[]): boolean => {
        for (let i = 1; i < s.length; i++) {
            const e1: Element = s[i - 1];
            const e2: Element = s[i];
            if (!this.checkRule(e1, e2, rule)) {
                return false;
            } // sequence doesn't match rule
        }
        return true;
    };

    checkUserCondition = (rule: Rule, sequences: Element[][]): boolean => {
        for (const sequence of sequences) {
            if (!this.checkSequence(rule, sequence)) {
                return false;
            } // sequence doesn't match user's rule
        }
        return true;
    };

    checkRule = (e1: Element, e2: Element, r: Rule): boolean => {
        switch (r.type) {
            case 'single':
                // if first element doesn't match condition, second one could be anything
                if (!this.checkElementCondition(e1, r.conditionIf)) {
                    return true;
                }
                return this.checkElementCondition(e2, r.conditionThen); // check
            case 'and':
                // if first element doesn't match both conditions, second one could be anything
                if (
                    !this.checkElementCondition(e1, r.conditionIf) ||
                    !this.checkElementCondition(e1, r.conditionAndOr as Condition)
                ) {
                    return true;
                }
                return this.checkElementCondition(e2, r.conditionThen); // check
            case 'or':
                if (
                    this.checkElementCondition(e1, r.conditionIf) ||
                    this.checkElementCondition(e1, r.conditionAndOr as Condition)
                ) {
                    return this.checkElementCondition(e2, r.conditionThen);
                } else {
                    return true;
                } // if first element doesn't match any of conditions, second one could be anything

            default:
                return false;
        }
    };
}

const gameService = new GameService();
export default gameService;

import { StaticticRound } from './statisticRound';
import { SeriesOption } from '../types/SeriesOption';

export class StatisticLevel {
  private levelOptions: SeriesOption | null;
  private roundsStorage: StaticticRound[];
  private isDone: boolean = false;

  public currentRound: StaticticRound;

  constructor(levelOptions: SeriesOption | null) {
    this.levelOptions = levelOptions;
    this.currentRound = new StaticticRound();
    this.roundsStorage = new Array<StaticticRound>();
  }

  public wrongAnswer() {
    this.currentRound.addWrongAnswer();
  }

  public nextRound(isPreviousRoundDone: boolean) {
    this.currentRound.finishRound(isPreviousRoundDone);
    this.roundsStorage.push(this.currentRound);
    this.currentRound = new StaticticRound();
  }

  public doneLevel(isPreviousRoundDone: boolean) {
    this.nextRound(isPreviousRoundDone);
    this.isDone = true;
  }

  public abort() {
    this.currentRound.finishRound(false);
    this.roundsStorage.push(this.currentRound);
  }

  public getLevelStatistic() {
    const levelStatistic = new Array<any>();
    let passTime = 0;
    this.roundsStorage.forEach(round => {
      levelStatistic.push(round.getRoundStatistic());
      passTime += round.getPassTime();
    });
    return {
      levelOptions: this.levelOptions,
      levelPassTime: passTime,
      rounds: levelStatistic,
    };
  }
}

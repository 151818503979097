import React, { Component, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import AppState from '../../types/AppState';
import GameRow from './GameRow';
import gameService from '../../service/gameService';
import Condition from '../../types/Condition';
import GameSelect from './GameSelect';
import { SelectorType } from '../../types/SelectorType';
import { setCurrentRound, setCurrentRoundInd, setDifficult } from '../../store/actions/general';
import GeneralState from '../../types/GeneralState';
import { StatisticService } from '../../service/statisticService';
import storeService from '../../service/storeService';
import integrationService from '../../service/integrationService';
import GameSettings from '../../components/GameSettings/GameSettings';

interface IGameRoundProps {
    mainState: GeneralState;
    setCurrentRound: typeof setCurrentRound;
    setCurrentRoundInd: typeof setCurrentRoundInd;
    setDifficult: typeof setDifficult;
}

interface IGameRoundState {
    // for selectors
    conditionIf: Condition;
    conditionAndOr: Condition;
    conditionThen: Condition;
    isRoundPassed: boolean;
    isRoundDone: boolean;
    isNextLevelAvailable: boolean;
    isAllLevelsFinished: boolean;
}

class GameRound extends Component<IGameRoundProps, IGameRoundState> {
    private statisticService: StatisticService;

    constructor(props: IGameRoundProps) {
        super(props);

        this.state = {
            conditionIf: 'big',
            conditionAndOr: 'big',
            conditionThen: 'big',
            isRoundPassed: false,
            isRoundDone: false,
            isNextLevelAvailable: false,
            isAllLevelsFinished: false,
        };

        let options = null;
        if (this.props.mainState.options) {
            options = this.props.mainState.options[this.props.mainState.currentDifficulty];
        }
        this.statisticService = new StatisticService(options);
    }

    onElemSelected = async (event: ChangeEvent<HTMLSelectElement>) => {
        const targetName: SelectorType = +event.target.name;
        const stateKey = SelectorType[targetName];
        await this.setState({ ...this.state, [stateKey]: event.target.value });
    };

    getRoundCount = (currentDifficulty: number): number => {
        return this.props.mainState.options
            ? +this.props.mainState.options[currentDifficulty].roundsCount
            : 0;
    };

    endGame = () => {
        let urlParams = storeService.getStore().getState().general.urlParams;
        integrationService.sendResult(urlParams, this.statisticService.getSessionStatistic());
        integrationService.sendEnd(urlParams);

        this.getStatisticReport();

        // @ts-ignore
        document.querySelectorAll('.button').forEach(item => (item.hidden = true));
        alert('Активность успешно пройдена!');
    };

    getStatisticReport = () => {
        const report = this.statisticService.getSessionStatistic(); // STATISTIC
        // tslint:disable-next-line: no-console
        console.log('report', report);
        // tslint:disable-next-line: no-console
        console.log('JSON.stringify(report)', JSON.stringify(report));
    };

    nextRound = () => {
        this.setState({ isRoundPassed: false });
        const curInd = +this.props.mainState.currentRoundIndex;
        this.props.setCurrentRoundInd(curInd + 1);
        const series = this.props.mainState.options;
        const difficulty = this.props.mainState.currentDifficulty;
        // tslint:disable-next-line: no-unused-expression
        series && gameService.newGame({ series }, difficulty);
        this.statisticService.passRound(this.state.isRoundDone); // STATISTIC
    };

    nextLevel = () => {
        const newLevel = this.props.mainState.currentDifficulty + 1;

        if (this.props.mainState.options && newLevel <= this.props.mainState.options.length - 1) {
            this.props.setCurrentRoundInd(0);
            this.props.setDifficult(newLevel);

            gameService.newGame({ series: this.props.mainState.options }, newLevel); // next level
            this.statisticService.newLevel(this.props.mainState.options[newLevel]); // STATISTIC

            this.setState({ isNextLevelAvailable: false });
        } else {
            // all levels done
            this.setState({ isNextLevelAvailable: false, isAllLevelsFinished: true });
            this.getStatisticReport(); // STATISTIC
        }
    };

    checkRule = () => {
        const currentRound = { ...this.props.mainState.currentRound };

        const mode = currentRound.rule.type;

        const userRule = {
            type: mode,
            conditionIf: this.state.conditionIf,
            conditionAndOr: this.state.conditionAndOr || null,
            conditionThen: this.state.conditionThen,
        };

        const { sequences } = currentRound;

        const mainCondition =
            currentRound.rule.conditionIf === this.state.conditionIf &&
            currentRound.rule.conditionThen === this.state.conditionThen &&
            currentRound.attemptsLeft > 0;

        const userCondition = gameService.checkUserCondition(userRule, sequences);

        const gameCondition =
            mode === 'single'
                ? mainCondition
                : mainCondition && currentRound.rule.conditionAndOr === this.state.conditionAndOr;

        const roundCount = this.getRoundCount(this.props.mainState.currentDifficulty);
        const curInd = +this.props.mainState.currentRoundIndex;

        if (gameCondition || userCondition) {
            // correct answer
            this.setState({ isRoundPassed: true });
        } else {
            // wrong answer
            currentRound.attemptsLeft--;
            this.statisticService.wrongAnswer(); // STATISTIC
            if (currentRound.attemptsLeft > 0) {
                // new sequence in game field
                const { rule } = this.props.mainState.currentRound;
                const length = this.props.mainState.currentRound.settings.sequenceLength;
                const newSequence = gameService.generateRandomSequence(rule, length);
                currentRound.sequences.push(newSequence);
                currentRound.sequenceCount++;
            } else {
                // round fail - attempts over
                this.setState({
                    isRoundPassed: true,
                });
            }
        }

        let isAllLevelsFinished = currentRound.settings.mode == "and";
        if (curInd + 1 === +roundCount && currentRound.attemptsLeft < 1) {
            // level done
            this.statisticService.passLevel(this.state.isRoundDone); // STATISTIC
            this.setState({
                isNextLevelAvailable: true,
                isRoundPassed: false,
                isRoundDone: false,
                isAllLevelsFinished: isAllLevelsFinished
            });
        } else if (
            (curInd + 1 === +roundCount && gameCondition) ||
            (curInd + 1 === +roundCount && userCondition)
        ) {
            this.statisticService.passLevel(this.state.isRoundDone); // STATISTIC

            this.setState({
                isNextLevelAvailable: true,
                isRoundPassed: true,
                isRoundDone: true,
                isAllLevelsFinished: isAllLevelsFinished
            });
        }

        this.props.setCurrentRound(currentRound);
    };

    // tslint:disable-next-line: member-ordering
    private btnCase = {
        checkBlock: (
            <div className="game-footer">
                <button className="button button_l button_red" onClick={this.checkRule}>
                    Проверить
                </button>
            </div>
        ),
        nextRoundBlock: (
            <div className="game-footer">
                <div className="game__success">Правильно!</div>
                <button className="button button_l button_red" onClick={this.nextRound}>
                    Следующий раунд
                </button>
            </div>
        ),
        attemptsLeftBlock: (
            <div className="game-footer">
                <div className="game__fail">Неправильно!</div>
                <button className="button button_l button_red" onClick={this.nextRound}>
                    Следующий раунд
                </button>
            </div>
        ),
        levelDoneBlock: (
            <div className="game-footer">
                <div className="game__success">Сложность пройдена!</div>
                <button className="button button_l button_red" onClick={this.nextLevel}>
                    Следующий уровень
                </button>
                <button className="button button_l button_red" onClick={this.endGame}>
                    Завершить игру
                </button>
            </div>
        ),
        gameFinishBlock: (
            <div className="game-footer">
                <div className="game__success">Игра пройдена!</div>
                <button className="button button_l button_red" onClick={this.endGame}>
                    Завершить игру
                </button>
            </div>
        ),
    };

    render() {
        const rows = [];
        for (let i = 0; i < this.props.mainState.currentRound.sequences.length; i++) {
            rows.push(
                <GameRow
                    key={'row' + i}
                    sequence={this.props.mainState.currentRound.sequences[i]}
                ></GameRow>
            );
        }

        const roundCount = this.getRoundCount(this.props.mainState.currentDifficulty);

        const conditionIf = (
            <GameSelect
                type={SelectorType.conditionIf}
                selectedElem={this.state.conditionIf}
                onElemSelected={this.onElemSelected}
            />
        );

        const conditionThen = (
            <GameSelect
                type={SelectorType.conditionThen}
                selectedElem={this.state.conditionThen}
                onElemSelected={this.onElemSelected}
            />
        );

        const mode = this.props.mainState.currentRound.rule.type;
        const conditionAndOr =
            mode !== 'single' ? (
                <GameSelect
                    type={SelectorType.conditionAndOr}
                    selectedElem={this.state.conditionAndOr}
                    onElemSelected={this.onElemSelected}
                />
            ) : null;
        const additionalCondition = mode === 'and' ? ' и ' : mode === 'or' ? ' или ' : '';

        const getBtnCase = () => {
            if (
                this.props.mainState.currentRound.attemptsLeft > 0 &&
                !this.state.isNextLevelAvailable
            ) {
                const block = this.state.isRoundPassed
                    ? this.btnCase.nextRoundBlock
                    : this.btnCase.checkBlock;
                return block;
            } else if (
                this.props.mainState.currentRound.attemptsLeft === 0 &&
                !this.state.isNextLevelAvailable
            ) {
                return this.btnCase.attemptsLeftBlock;
            } else if (this.state.isAllLevelsFinished) {
                return this.btnCase.gameFinishBlock;
            } else if (this.state.isNextLevelAvailable) {
                return this.btnCase.levelDoneBlock;
            }
        };

        return (
            <div className="game-status">
                <div className="game-settings">
                    <p>Текущий уровень сложности: {this.props.mainState.currentDifficulty + 1}</p>
                    <p>
                        Текущий раунд: {this.props.mainState.currentRoundIndex + 1}/{roundCount}
                    </p>
                    <p>Осталось попыток: {this.props.mainState.currentRound.attemptsLeft}</p>
                </div>
                <div className="game-body">{rows}</div>
                <div className="game-choice">
                    <span>
                        Правило: <b>если</b>
                    </span>{' '}
                    {conditionIf} {additionalCondition} {conditionAndOr},
                    <span>
                        <b>то</b>
                    </span>{' '}
                    {conditionThen}
                </div>
                {getBtnCase()}
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    mainState: state.general,
    setCurrentRound,
    setCurrentRoundInd,
    setDifficult,
});

export default connect(mapStateToProps, { setCurrentRound, setCurrentRoundInd, setDifficult })(
    GameRound
);

import actions from './types';
import StoreAction from '../../types/StoreAction';

export const setOption = (value: any): StoreAction => {
    return {
        type: actions.SET_OPTIONS,
        payload: value,
    };
};

export const setCurrentRound = (value: any): StoreAction => {
    return {
        type: actions.SET_CURRENT_ROUND,
        payload: value,
    };
};

export const clearStats = (): StoreAction => {
    return {
        type: actions.CLEAR_STATS,
        payload: {},
    };
};

export const setCurrentRoundInd = (value: any): StoreAction => {
    return {
        type: actions.SET_CURRENT_ROUNT_IND,
        payload: value,
    };
};

export const setDifficult = (value: any): StoreAction => {
    return {
        type: actions.SET_DIFFICULT,
        payload: value,
    };
};

export const setUrlParams = (value: any): StoreAction => {
    return {
        type: actions.SET_URL_PARAMS,
        payload: value,
    };
};

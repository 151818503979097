import React, {Component} from 'react';
import {connect} from 'react-redux';
import GameRound from './GameRound';
import GeneralState from '../../types/GeneralState';

interface IGameProps {
    state: GeneralState;
}

class Game extends Component<IGameProps> {
    render() {
        console.log('game state', this.props);

        return (
            <div>
                <div className="rules">
                    <div className="rules-header">
                        <p className="rules-header__title">Правила:</p>
                    </div>
                    <div className="rules-items">
                        <div className="rules-item">
                            <p>Игра предлагает последовательности фигур, для которых всегда выполняется правило вида:</p>
                            <ul>
                                <li><b>если</b> предыдущая фигура обладает свойством «а», <b>то</b> следующая фигура обладает свойством «б».</li>
                            </ul>
                        </div>
                        <div className="rules-item">
                            <p>Например:</p>
                            <ul>
                                <li><b>если</b> большой, <b>то</b> квадрат;</li>
                                <li><b>если</b> маленький, <b>то</b> круг;</li>
                                <li><b>если</b> квадрат, <b>то</b> белый.</li>
                            </ul>
                        </div>
                        <div className="rules-item">
                            <p>Обратите внимание, что в правиле не могут встречаться одинаковые свойства. Например, игра не может задать правило:</p>
                            <ul>
                                <li><b>если</b> большой, <b>то</b> большой.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <GameRound/>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    state: state.general,
});

export default connect(mapStateToProps)(Game);

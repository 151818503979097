import actions from '../actions/types';
import { updateObject } from './utility';
import StoreAction from '../../types/StoreAction';
import GeneralState from '../../types/GeneralState';

const initialState: GeneralState = {
  options: null,
  currentRound: {
    // maybe not the best solution, but I try to avoid currentRound
    // having type "RoundInfo | null" so that's initial state that
    // will never be used
    sequenceCount: 1,
    attemptsLeft: 1,
    settings: {
      mode: 'single',
      sequenceLength: 1,
      sequencesAtStart: 1,
      attemptsCount: 1,
    },
    rule: {
      type: 'single',
      conditionIf: 'big',
      conditionAndOr: null,
      conditionThen: 'black',
    },
    sequences: [],
  },
  currentRoundIndex: 0,
  currentDifficulty: 0,
  stats: null,
  urlParams: {}
};

export default (state: GeneralState = initialState, action: StoreAction) => {
  switch (action.type) {
    case actions.SET_OPTIONS:
      return updateObject(state, { options: action.payload });
    case actions.SET_CURRENT_ROUND:
      return updateObject(state, { currentRound: action.payload });
    case actions.CLEAR_STATS:
      return updateObject(state, { stats: {} });
    case actions.SET_CURRENT_ROUNT_IND:
      return updateObject(state, { currentRoundIndex: action.payload });
    case actions.SET_DIFFICULT:
      return updateObject(state, { currentDifficulty: action.payload });
    case actions.SET_URL_PARAMS:
      return updateObject(state, { urlParams: action.payload });

    default:
      return state;
  }
};


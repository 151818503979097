import React, { Component } from 'react';
import Element from '../../types/Element';
import GameElement from './GameElement';

interface IGameRowProps {
  sequence: Element[];
}

class GameRow extends Component<IGameRowProps> {
  render() {
    let elements = [];
    for (let i = 0; i < this.props.sequence.length; i++) {
      elements.push(<GameElement key={'el' + i} element={this.props.sequence[i]}></GameElement>);
    }

    return <div>{elements}</div>;
  }
}

export default GameRow;
